export default {
  props: {
    to: {
      type: Object,
      required: false,
    },
    eventName: {
      type: String,
      default: 'click',
    },
    iconClass: {
      type: String,
      required: false,
    },
    btnClasses: {
      type: String,
      default: null,
    },
    btnClassesMobile: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
